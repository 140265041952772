<template>
  <div>
    <CaHeader
      heading="Vermittlergruppen"
      :column-layout="true"
    >
      <template #buttonSpace>
        <router-link
          v-if="!$can('createAgentGroup')"
          class="btn btn-primary"
          :to="{name: 'AgentGroupCreate' }"
        >
          Neue Vermittlergruppe
        </router-link>
      </template>
    </CaHeader>
    <div class="container-fluid px-4 pt-4">
      <DataTable
        :fields="fields"
        service="agent-groups"
        title="Vermittlergruppen"
        @row-clicked="(item) => this.$router.push({ name: 'AgentGroupEdit', params: { agentGroupId: item._id } })"
      />
    </div>
  </div>
</template>

<script>
import DataTable from '@/components/DataTable/DataTable.vue'
import CaHeader from '@/components/Layout/Header'

export default {
  components: {
    CaHeader,
    DataTable
  },
  data: () => ({
    fields: [
      {
        label: 'Nummer',
        key: 'number'
      },
      {
        label: 'Name',
        key: 'name'
      },
      {
        label: 'Name 1',
        key: 'name1'
      }
    ]
  }),
  methods: {
  }
}
</script>
